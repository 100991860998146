<template>
  <div>
    <section class="contact">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form class="contact-form" method="POST">
              <h1>{{ $t('contact.title') }}</h1>
              <div class="form-group">
                <label for="nameInput">{{ $t('contact.name.title') }}</label>
                <input type="text" name="name" class="form-control form-control-lg" id="nameInput" v-bind:placeholder="$t('contact.name.placeholder')">
              </div>
              <div class="form-group">
                <label for="emailInput">{{ $t('contact.email.title') }}</label>
                <input type="email" name="email" class="form-control form-control-lg" id="emailInput" v-bind:placeholder="$t('contact.email.placeholder-start') + '@' + $t('contact.email.placeholder-end')">
              </div>
              <div class="form-group">
                <label for="emailInput">{{ $t('contact.message.title') }}</label>
                <textarea rows="6" name="message" class="form-control form-control-lg" id="emailInput" v-bind:placeholder="$t('contact.message.placeholder')"></textarea>
              </div>
              <button type="submit" name="submit" class="btn btn-primary">{{ $t('contact.sendMessage-button') }}</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
  @import '../assets/scss/core';
  @import '../assets/scss/contact';

  section.contact {
    background: #ffffff;
    @include media(lg) {
      background: #f1f1f1;
    }
  }
</style>